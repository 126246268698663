.container {
  flex-direction: column;
  margin: auto;
  width: 100%;
  background-color: transparent !important;
  padding: 30px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-top: 30px;
}

.input {
  color: #fff;
}

.Table {
  overflow: hidden;
}

.submit-container {
  display: flex;
  gap: 30px;
  margin: 10px auto;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 39px;
  color: #fff;
  background: rgb(161, 7, 7);
  border-color: rgb(161, 7, 7);
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.text {
  color: black;
  background: rgb(186, 184, 184);
  font-size: 48px;
  font-weight: 700;
}

.dashboard-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.data-box {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 45%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-box h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.checkbox-item {
  color: #fff;
  align-items: center;
  padding-top: 40px;
  border-radius: 7px;
  font-size: 20px;
  padding-left: 40px;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}

.title {
  font-weight: bold;
  font-size: 30px;
  color: #022e77;
}

.checkbox {
  background-color: #022e77;
  border-radius: 7px;
  display: inline-block;
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  align-items: center;
}

@media (max-width: 992px) {
  .checkbox-grid {
    display: inline;
    align-items: center;
    grid-template-columns: none;
    gap: 10px;
  }
}

@media (max-width: 992px) {
  .categoryOptions {
    display: inline;
    align-items: center;
    grid-template-columns: none;
    gap: 10px;
  }
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.label {
  color: black;
}

.categoryOptions {
  margin-left: 200px;
  margin-right: 200px;
}

@media (max-width: 1200px) {
  .categoryOptions{
    margin-left: 50px;
    margin-right: 200px;
  }
  
}


/* Navbar Styling */
.nav-buttons {
  display: flex;
  align-items: center; /* Center items vertically */
  /* margin-bottom: 10px;
  padding:10px ; Adjust as needed */
}

.nav-buttons a, .nav-buttons button {
  margin: 0 20px;
  padding: 10px 20px 30px; /* Adjusted padding */
  width: 100px;
  height: 40px;
  color: rgb(243, 239, 233) !important;
  background-color: #022E77;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1; /* Distribute space evenly */
  text-align: center; /* Center text */
  /* line-height: 40px; Vertically center text */
  font-size: 16px;
}

.nav-buttons a:hover, .nav-buttons button:hover {
  background-color: #033B8B;
}

.nav-buttons {
  margin-left: auto; /* Push buttons to the right */
}

.navbar {
  height: 84px;
  background-color: lightgray !important;
}

.navbar .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: -2px 20px; /* Add horizontal padding */
  background-color: white ;
  border-bottom: solid lightgray  1px;
}

.navbar .navbar-header {
  display: flex;
  align-items: center;
}

.navbar .navbar-header .b-brand {
  padding: 10px; /* Add padding around the logo */
}

.navbar .nav-buttons {
  display: flex;
  align-items: center;
}

.navbar .nav-link, .nav-buttons .btn {
  margin: 0 10px;
  padding: 10px 20px;
  color: #fff;
  background-color: #022E77;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  line-height: 40px; /* Vertically center text */
}

.navbar .nav-link:hover, .nav-buttons .btn:hover {
  background-color: #033B8B;
}

@media (max-width: 767.98px) {
  .navbar .container-fluid {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar .navbar-header {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 3px; /* Add horizontal padding on mobile */
  }

  .navbar .collapse {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .navbar .nav-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .navbar .nav-link, .nav-buttons .btn {
    margin: 3px;
    padding: 5px 50px 10px;
    width: 50px;
    height: 30px;
    line-height: 30px; /* Ensure text is centered */
  }

 
}

/* Media Query for iPads and Tablets */
@media (max-width: 1024px) {
  .navbar .container-fluid {
    flex-direction: row;
    justify-content: space-between;
    padding: 0; /* Remove padding for the container */
  }
 
  .navbar .navbar-header {
    flex: 0 1 auto; /* Adjust flex properties to prevent unnecessary space */
    display: flex;
    align-items: center;
    margin: 0; /* Remove margin to ensure the logo starts at the beginning */
  }
 
  .navbar .navbar-header .b-brand {
    display: flex;
    align-items: center;
    padding-left: 10px; /* Optional: Add slight padding if the logo is too close to the edge */
  }
 
  .navbar .collapse {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0; /* Remove padding for the collapse */
  }
 
  .navbar .nav-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
 
  .navbar .nav-link, .nav-buttons .btn {
    margin: 0 5px; /* Adjust margin for buttons */
    padding: 8px 12px; /* Adjust padding for buttons */
    width: 70px;
    height: 35px; /* Remove fixed height for flexibility */
    align-items: center;
    line-height: 2.2; /* Reset line-height to normal */
    font-size: 15px; /* Ensure text is centered */
  }

 
}

/* Media Query for Large Screens and Foldable Devices */
@media (max-width: 320px){
  .navbar .container-fluid {
    flex-direction: row;
    justify-content: space-between;
  }

  .navbar .navbar-header {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 10px; /* Add horizontal padding */
  }

  .navbar .collapse {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .navbar .nav-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .navbar .nav-link, .nav-buttons .btn {
    margin: 5px;
    padding: 5px 10px;
    width: 50px;
    height: 30px;
    line-height: 30px; /* Ensure text is centered */
  }

  
}

.radio-label {   
  display: flex;   
  align-items: center; 
} 

.radio-label input {  
   margin-right: 10px;
  /* Adjust the space as needed */
  }

  .name{
    font-size: 18px;
    font-weight: 400;
    
  }

  .flex{
    display:flex;
  }

  .title-name{
    background-color: #022e77;
    font-size: 30px;
    color:white;
    border-radius: 7px;
   }


   /* Forum.css */
.list-group-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-group-item:hover {
  background-color: #f0f0f0;
}

textarea {
  resize: vertical;
}

form {
  margin-top: 20px;
}

.btn-primary {
  margin-top: 10px;
}

.forum-slider {
  max-height: 350px; /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.checkbox-wrapper-37 input[type="checkbox"] {
          display: none;
        }

        .checkbox-wrapper-37 .terms-label {
          display: flex;
          align-items: center;
          border: solid thin;
          border-radius: 8px;
          background-color: #022e77;
          width: 58%;
          padding: 15px;
        }

        .checkbox-wrapper-37 .terms-label .label-text {
         margin-left: 5%;
         color: white;
         margin-top: 8%;
         margin-bottom: 8%;
        }

        .checkbox-wrapper-37 .checkbox-svg {
          width: 30px;
          height: 30px;
        }

        .checkbox-wrapper-37 .checkbox-box {
          fill: #fff;
          stroke-dasharray: 800;
          stroke-dashoffset: 800;
          transition: stroke-dashoffset 0.6s ease-in;
        }

        .checkbox-wrapper-37 .checkbox-tick {
          stroke: #ff7a00;
          stroke-dasharray: 172;
          stroke-dashoffset: 172;
          transition: stroke-dashoffset 0.6s ease-in;
        }

        .checkbox-wrapper-37 input[type="checkbox"]:checked + .terms-label .checkbox-box,
        .checkbox-wrapper-37 input[type="checkbox"]:checked + .terms-label .checkbox-tick {
          stroke-dashoffset: 0;
        }

        .submit-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 39px;
          color: #fff;
          background: rgb(161, 7, 7);
          border-color: rgb(161, 7, 7);
          border-radius: 5px;
          font-size: 13px;
          font-weight: 400;
          cursor: pointer;
         
        }
        
        .forum-slider {
          /* max-height: 350px; Adjust height as needed */
          overflow-y: auto;
          /* border: 1px solid #ddd;
          border-radius: 4px; */
          padding: 10px;
        }
        
        .Topics{
          color:#022E77;
          font-size: 20px;
        }

        .searchbar{
          width:100%;
          margin-top: 17px;
          height: 39px;
          border-radius: 5px 0px 0px 5px;
        }

        .search-btn{
          height: 39px;
          margin-top: 17px;
          border-radius: 0px 5px 5px 0px;
          background: rgb(161, 7, 7);
          border-color: rgb(161, 7, 7);
          font-size: 13px;
          font-weight: 400;
          cursor: pointer;
          align-items: center;
          padding:0px
        }

        .lable{
          font-size: 11px;
          padding-left:3px;
          padding-top:8px;
        }

        .userPhoto{
        width: 50px;
       height: 50px;
       border-Radius:50%;
       object-Fit: cover;
       border: 1px solid black;
        }

        .userImg{
          height: 50px;
          width:50px;
        }

        .bi{
          width:16px; 
          height:16px;
          
        }
        

        